import { useReducer, useContext } from "react";
import { addPost, getPosts } from "../api";
import { StateContext } from "..//providers/StateContext";
type AddPostCardProps = {
  username: string;
};

type AddPostCardState = {
  postTitle: string;
  postContent: string;
  imageLink: string;
};
type AddPostCardAction =
  | { type: "SetPostTitle"; payload: string }
  | { type: "SetPostContent"; payload: string }
  | { type: "SetImageLink"; payload: string }
  | { type: "Reset" };

function AddPostCard({ username }: AddPostCardProps) {
  const { dispatch: globalDispatch } = useContext(StateContext);

  const initialState: AddPostCardState = {
    postTitle: "",
    postContent: "",
    imageLink: "",
  };
  const reducer = (state: AddPostCardState, action: AddPostCardAction) => {
    switch (action.type) {
      case "SetPostTitle":
        return { ...state, postTitle: action.payload };
      case "SetPostContent":
        return { ...state, postContent: action.payload };
      case "SetImageLink":
        return { ...state, imageLink: action.payload };
      case "Reset":
        return initialState;
      default:
        throw new Error();
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleAddPost = async () => {
    if (state.imageLink === "") {
      return addPost(state.postTitle, username, state.postContent, undefined)
        .then(() => getPosts())
        .then((posts) => {
          globalDispatch({ type: "SetPosts", payload: posts });
          dispatch({ type: "Reset" });
        });
    } else {
      return addPost(
        state.postTitle,
        username,
        state.postContent,
        state.imageLink
      ).then(() => {
        dispatch({ type: "Reset" });
      });
    }
  };
  return (
    <div className="flex flex-col shadow bg-white m-3 rounded-md px-3 py-3">
      <form>
        <label className="font-bold">Title:</label>
        <div className="flex my-3">
          <input
            type="text"
            placeholder="Post title"
            value={state.postTitle}
            onChange={(e) =>
              dispatch({ type: "SetPostTitle", payload: e.target.value })
            }
            className="w-full bg-gray-200 rounded-full border-none"
          />
        </div>
        <label className="font-bold">Content:</label>
        <div className="flex my-3">
          <input
            type="text"
            placeholder="What's on your mind?"
            value={state.postContent}
            onChange={(e) =>
              dispatch({ type: "SetPostContent", payload: e.target.value })
            }
            className="w-full bg-gray-200 rounded-full border-none"
          />
        </div>

        <label className="font-bold">Image Link &#40;optional&#41;:</label>
        <div className="flex my-3">
          <input
            type="text"
            placeholder="Image link"
            value={state.imageLink}
            onChange={(e) =>
              dispatch({ type: "SetImageLink", payload: e.target.value })
            }
            className="w-full bg-gray-200 rounded-full border-none"
          />
        </div>
        <div>
          <button
            type="button"
            className="mt-3 bg-yellow-500 py-1 px-3 rounded text-white font-bold w-full"
            onClick={() => handleAddPost()}
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
}
export default AddPostCard;

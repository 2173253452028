import Notification from "./Notification";
import { render } from "react-dom";
export const createTextLinks = (text: string): string => {
  return (text || "").replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    (_, space: string, url: string) => {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        space +
        '<a class="text-blue-500 underline" href="' +
        hyperlink +
        '">' +
        url +
        "</a>"
      );
    }
  );
};

export const notifyOnError = (title: string, content: string) => {
  render(
    <Notification title={title} message={content} />,
    document.getElementById("notification-fixed-container")
  );
};

import { useContext } from "react";
import cloudflareLogo from "../assets/cloudflareLogo.png";
import defaultProfilePicture from "../assets/defaultProfilePicture.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../providers/UserContext";
type NavProps = {
  username?: string;
  profilePicture?: string;
};

function Nav({ username, profilePicture }: NavProps) {
  const { dispatch } = useContext(UserContext);
  const profileName = username ? username : "No Profile";
  const handleLogout = () => {
    dispatch("");
  };
  return (
    <nav className="w-full shadow-md bg-white">
      <div className="flex justify-between items-center">
        <img
          src={cloudflareLogo}
          alt="Cloudflare logo"
          width="45"
          height="45"
          className="rounded-full mx-3 my-1 cursor-pointer"
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        />
        <div className="flex items-center h-full w-50">
          <div className="text-xs font-bold">{profileName}</div>
          <img
            src={profilePicture ? profilePicture : defaultProfilePicture}
            alt={profileName}
            width="35"
            height="35"
            className="rounded-full mx-3 my-1"
          />
          <FontAwesomeIcon
            onClick={() => handleLogout()}
            className="text-gray-500 mr-3 hover:text-yellow-500 cursor-pointer"
            icon={faSignOutAlt}
            width="35"
            height="35"
          />
        </div>
      </div>
    </nav>
  );
}
export default Nav;

import { useContext } from "react";
import { Comment } from "../types";
import defaultProfilePicture from "../assets/defaultProfilePicture.jpeg";
import { StateContext } from "../providers/StateContext";
type PostCardCommentProps = {
  comment: Comment;
};
function PostCardComment({ comment }: PostCardCommentProps) {
  const { state } = useContext(StateContext);

  const myUser = state.users.find(
    (_user) => _user.username === comment.username
  );
  const pfp =
    myUser === undefined || myUser.image === ""
      ? defaultProfilePicture
      : myUser.image;

  const date = new Date(comment.timestamp);
  return (
    <div className="flex flex-row my-6">
      <div className="w-1/12">
        <img
          src={pfp}
          alt={comment.username}
          width="40"
          height="40"
          className="rounded-full"
        />
      </div>
      <div className="flex flex-col pl-3 w-11/12">
        <span className="flex flex-row">
          <div className="text-xs font-bold">{comment.username}</div>
          <div className="text-xs">&nbsp;{date.toLocaleString()}</div>
        </span>
        <span>{comment.content}</span>
      </div>
    </div>
  );
}
export default PostCardComment;

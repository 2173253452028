import { useEffect, useState } from "react";
import { unmountComponentAtNode } from "react-dom";
type NotificationProps = { title: string; message: string; timer?: number };
const Notification = ({ title, message, timer }: NotificationProps) => {
  const [closeTimeout, setCloseTimeout] = useState<number | null>(null);

  const close = () => {
    if (closeTimeout !== null) window.clearTimeout(closeTimeout);
    const el = document.getElementById("notification-fixed-container");
    if (el !== null) unmountComponentAtNode(el);
  };
  const beginCloseTimeout = () => {
    if (timer !== undefined) {
      const timeout = window.setTimeout(() => close(), timer);
      setCloseTimeout(timeout);
    } else {
      const timeout = window.setTimeout(() => close(), 3000);
      setCloseTimeout(timeout);
    }
  };

  useEffect(() => {
    beginCloseTimeout();
  }, []);
  return (
    <div className="flex flex-col ml-3 shadow-lg bg-white border-gray-300 border p-3 rounded-lg">
      <span className="font-bold">{title}</span>
      <span className="">{message}</span>
    </div>
  );
};

export default Notification;

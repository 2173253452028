import { KeyboardEvent, useState, useContext, useRef } from "react";
import { Post, Vote } from "../types";
import defaultProfilePicture from "../assets/defaultProfilePicture.jpeg";
import PostCardComment from "./PostCardComment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown as farArrowAltCircleDown,
  faArrowAltCircleUp as farArrowAltCircleUp,
  faCommentAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowAltCircleDown as fasArrowAltCircleDown,
  faArrowAltCircleUp as fasArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { addComment, updateVote, getPosts } from "../api";
import { StateContext } from "..//providers/StateContext";
import { createTextLinks } from "../util";

type PostCardProps = {
  post: Post;
  username: string;
};

function PostCard({ post, username }: PostCardProps) {
  const { state, dispatch } = useContext(StateContext);

  const stateUser = state.users.find((user) => user.username === post.username);
  const pfp = stateUser === undefined ? defaultProfilePicture : stateUser.image;
  const aggregateVoteCount = (votes: Vote[]): number => {
    const upVotes = votes.filter((vote) => vote.isUpvote === true).length;
    const downVotes = votes.length - upVotes;
    return upVotes - downVotes;
  };

  const [isActivated, setActivated] = useState(false);
  const userVote = post.votes.find((vote) => vote.username === username);

  const userUpvoted = userVote !== undefined && userVote.isUpvote === true;
  const userDownvoted = userVote !== undefined && userVote.isUpvote === false;

  const handleVote = async (isUpvote?: boolean) => {
    return updateVote(post.postId, username, isUpvote).then(() => {
      const idx = state.posts.findIndex(
        (_post) => _post.postId === post.postId
      );
      if (idx !== -1) {
        const newPosts = [...state.posts];
        const newPost = newPosts[idx];
        const newVotes = newPost.votes.filter(
          (vote) => vote.username !== username
        );
        if (isUpvote !== undefined) {
          newVotes.push({ username, isUpvote });
        }
        newPost.votes = newVotes;
        newPosts[idx] = newPost;
        dispatch({ type: "SetPosts", payload: newPosts });
      }
    });
  };
  const handleAddComment = (content: string) => {
    return addComment(post.postId, username, content);
  };

  const addCommentRef = useRef(null);
  const handleCommentKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const commentInput =
        addCommentRef.current !== null
          ? addCommentRef.current
          : event.currentTarget;
      const content = commentInput.value as string;
      handleAddComment(content)
        .then(() => getPosts())
        .then((posts) => {
          dispatch({ type: "SetPosts", payload: posts });
          commentInput.value = "";
        });
    }
  };

  return (
    <div
      className={`flex flex-col shadow bg-white m-3 rounded-md px-3 pt-3 ${
        isActivated && "pb-3"
      }`}
    >
      <div className="flex w-full">
        <div>
          <img
            src={pfp}
            alt={post.username}
            width="45"
            height="45"
            className="rounded-full mr-3 my-1"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-xs font-bold">{post.username}</span>
          <span className="text-xs">
            {new Date(post.timestamp).toLocaleString()}
          </span>
        </div>
      </div>
      <div className="font-bold">{post.title}</div>
      <div
        dangerouslySetInnerHTML={{ __html: createTextLinks(post.content) }}
      ></div>
      {post.image !== undefined && (
        <div className="mt-3">
          <img src={post.image} alt={post.title} />
        </div>
      )}
      <div
        className={`border-t-2 flex flex-row justify-between mt-3 py-1
        ${isActivated && "border-b-2"}`}
      >
        <div className="w-50 flex flex-row flex-grow justify-center items-center">
          {userUpvoted ? (
            <FontAwesomeIcon
              icon={fasArrowAltCircleUp}
              height="15"
              width="15"
              className="cursor-pointer transition-colors text-yellow-500 hover:text-black"
              onClick={() => handleVote()}
            />
          ) : (
            <FontAwesomeIcon
              icon={farArrowAltCircleUp}
              height="15"
              width="15"
              className="cursor-pointer transition-colors hover:text-yellow-500"
              onClick={() => handleVote(true)}
            />
          )}
          <div>
            &nbsp;&nbsp;&nbsp;{aggregateVoteCount(post.votes)}&nbsp;&nbsp;&nbsp;
          </div>
          {userDownvoted ? (
            <FontAwesomeIcon
              className="cursor-pointer transition-colors text-yellow-500 hover:text-black"
              icon={fasArrowAltCircleDown}
              height="15"
              width="15"
              onClick={() => handleVote()}
            />
          ) : (
            <FontAwesomeIcon
              className="cursor-pointer transition-colors hover:text-yellow-500"
              icon={farArrowAltCircleDown}
              height="15"
              width="15"
              onClick={() => handleVote(false)}
            />
          )}
        </div>
        <div
          className="w-50 flex flex-row flex-grow justify-center rounded transition-colors hover:bg-gray-200 items-center cursor-pointer select-none"
          onClick={() => setActivated(!isActivated)}
        >
          <FontAwesomeIcon icon={faCommentAlt} height="10" width="10" />
          <div className="flex self-center">
            &nbsp;&nbsp;&nbsp;{post.comments.length} Comments
          </div>
        </div>
      </div>
      {isActivated && (
        <>
          <div className="flex my-3">
            <input
              ref={addCommentRef}
              type="text"
              placeholder="Write a comment..."
              className="w-full bg-gray-200 rounded-full border-none"
              onKeyPress={handleCommentKeyPress}
            />
          </div>
          <div>
            {post.comments.map((comment) => (
              <PostCardComment
                key={
                  comment.username +
                  comment.timestamp.toString() +
                  comment.content
                }
                comment={comment}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default PostCard;

import React, { Context, createContext, useReducer, useEffect } from "react";

export interface User {
  username: string;
}

interface IUserContext {
  user: User;
  dispatch: React.Dispatch<string>;
}

const userReducer = (_: any, username: string): User => {
  return { username };
};

const UserContext: Context<IUserContext> = createContext({} as IUserContext);

const initialState = JSON.parse(
  localStorage.getItem("FlareSocialUser") as string
) || { username: "" };

const UserProvider: React.FC = ({ children }) => {
  const [user, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    localStorage.setItem("FlareSocialUser", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };

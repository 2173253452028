import React, { Context, createContext, useReducer } from "react";
import { User, Post } from "../types";

export interface State {
  users: User[];
  posts: Post[];
}

interface IStateContext {
  state: State;
  dispatch: React.Dispatch<StateAction>;
}

type StateAction =
  | { type: "SetUsers"; payload: User[] }
  | { type: "SetPosts"; payload: Post[] };

const stateReducer = (state: State, action: StateAction): State => {
  switch (action.type) {
    case "SetUsers":
      return { ...state, users: action.payload };
    case "SetPosts":
      return { ...state, posts: action.payload };
    default:
      throw new Error();
  }
};

const StateContext: Context<IStateContext> = createContext({} as IStateContext);

const initialState = { users: [], posts: [] };

const StateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <StateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateProvider, StateContext };

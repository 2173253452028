import {
  Post,
  PostInput,
  VoteInput,
  CommentInput,
  User,
  UserInput,
  ErrorPayload,
} from "./types";
import { notifyOnError } from "./util";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1:8787"
    : "https://flaresocial-workers.seentrifugal.workers.dev";

export const getPosts = async (): Promise<Post[]> => {
  const url = baseUrl + "/posts";

  return fetch(url).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
    return response.json();
  });
};

export const addPost = async (
  title: string,
  username: string,
  content: string,
  image?: string
): Promise<void> => {
  const url = baseUrl + "/posts";

  const postInput: PostInput = { title, username, content, image };

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postInput),
  }).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
  });
};

export const getUsers = async (): Promise<User[]> => {
  const url = baseUrl + "/users";

  return fetch(url).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
    return response.json();
  });
};

export const updateUser = async (
  username: string,
  image: string
): Promise<void> => {
  const url = baseUrl + "/users";

  const userInput: UserInput = { username, image };

  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInput),
  }).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
  });
};

export const updateVote = async (
  postId: string,
  username: string,
  isUpvote?: boolean
): Promise<void> => {
  const url = baseUrl + "/votes";

  const voteInput: VoteInput = { postId, username, isUpvote };

  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(voteInput),
  }).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
  });
};

export const addComment = async (
  postId: string,
  username: string,
  content: string
): Promise<void> => {
  const url = baseUrl + "/comments";

  const commentInput: CommentInput = { postId, username, content };

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(commentInput),
  }).then(async (response) => {
    if (response.status >= 400 && response.status < 600) {
      const err = (await response.json()) as ErrorPayload;
      console.error(err);
      notifyOnError(err.errorUserTitle, err.errorUserMsg);
    }
  });
};

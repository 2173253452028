import { useContext, useEffect } from "react";
import Nav from "../components/Nav";
import AddPostCard from "../components/AddPostCard";
import PostCard from "../components/PostCard";
import { UserContext } from "../providers/UserContext";
import { StateContext } from "../providers/StateContext";
import { getPosts, getUsers } from "../api";

function Feed() {
  const { user } = useContext(UserContext);
  const { state, dispatch: stateDispatch } = useContext(StateContext);

  useEffect(() => {
    getPosts().then((posts) => {
      stateDispatch({ type: "SetPosts", payload: posts });
    });
    getUsers().then((users) => {
      stateDispatch({ type: "SetUsers", payload: users });
    });
  }, []);

  const myUser = state.users.find((_user) => _user.username === user.username);
  const username =
    myUser === undefined || myUser.username === ""
      ? undefined
      : myUser.username;
  const pfp =
    myUser === undefined || myUser.image === "" ? undefined : myUser.image;

  return (
    <div className="w-full h-full">
      <div className="fixed w-full">
        <Nav username={username} profilePicture={pfp} />
      </div>
      <Nav />
      <div className="flex">
        <div className="flex flex-col max-w-screen-sm mx-auto">
          <AddPostCard username={user.username} />
          {state.posts.map((post) => (
            <PostCard key={post.postId} post={post} username={user.username} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Feed;

import { useContext } from "react";
import Feed from "./pages/Feed";
import Login from "./pages/Login";
import { UserContext } from "./providers/UserContext";

function Routes() {
  const { user } = useContext(UserContext);
  const isLoggedIn = user.username !== "";

  return <>{isLoggedIn ? <Feed /> : <Login />}</>;
}

export default Routes;

import { useContext, useEffect, useReducer } from "react";
import cloudflareLogo from "../assets/cloudflareLogo.png";
import { getUsers, updateUser } from "../api";
import { UserContext } from "../providers/UserContext";
import { StateContext } from "../providers/StateContext";

type LoginState = {
  registerUsername: string;
  registerImageLink: string;
  loginUsername: string;
};
type LoginAction =
  | { type: "SetRegisterUsername"; payload: string }
  | { type: "SetRegisterImageLink"; payload: string }
  | { type: "SetLoginUsername"; payload: string }
  | { type: "Reset" };

function Login() {
  const initialState: LoginState = {
    registerUsername: "",
    registerImageLink: "",
    loginUsername: "",
  };
  const { dispatch: userDispatch } = useContext(UserContext);
  const { state: globalState, dispatch: stateDispatch } = useContext(
    StateContext
  );

  useEffect(() => {
    getUsers().then((users) => {
      stateDispatch({ type: "SetUsers", payload: users });
    });
  }, []);

  const reducer = (state: LoginState, action: LoginAction) => {
    switch (action.type) {
      case "SetRegisterUsername":
        return { ...state, registerUsername: action.payload };
      case "SetRegisterImageLink":
        return { ...state, registerImageLink: action.payload };
      case "SetLoginUsername":
        return { ...state, loginUsername: action.payload };
      case "Reset":
        return initialState;
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleRegister = async () => {
    return updateUser(state.registerUsername, state.registerImageLink).then(
      () => {
        stateDispatch({
          type: "SetUsers",
          payload: [
            ...globalState.users,
            {
              username: state.registerUsername,
              image: state.registerImageLink,
            },
          ],
        });
        userDispatch(state.registerUsername);
      }
    );
  };
  const handleLogin = () => {
    userDispatch(state.loginUsername);
  };

  return (
    <div className="max-w-screen-sm h-full mx-auto">
      <div className="flex flex-col shadow bg-white m-3 rounded-md px-3 py-3">
        <div className="flex flex-row items-center justify-center">
          <img
            src={cloudflareLogo}
            alt="Cloudflare logo"
            height="100"
            width="100"
            className="mx-1"
          />
          <div className="text-3xl font-extrabold mx-1">FlareSocial</div>
        </div>
        <form>
          <h2 className="font-bold text-2xl mb-3">Sign Up</h2>
          <label className="font-bold">Username:</label>
          <div className="flex my-3">
            <input
              type="text"
              placeholder="Username"
              value={state.registerUsername}
              onChange={(e) =>
                dispatch({
                  type: "SetRegisterUsername",
                  payload: e.target.value,
                })
              }
              className="w-full bg-gray-200 rounded-full border-none"
            />
          </div>
          <label className="font-bold">Image link:</label>
          <div className="flex my-3">
            <input
              type="text"
              placeholder="Image link"
              value={state.registerImageLink}
              onChange={(e) =>
                dispatch({
                  type: "SetRegisterImageLink",
                  payload: e.target.value,
                })
              }
              className="w-full bg-gray-200 rounded-full border-none"
            />
          </div>
          <div>
            <button
              type="button"
              className="mt-3 bg-yellow-500 py-1 px-3 rounded text-white font-bold w-full"
              onClick={() => handleRegister()}
            >
              Register
            </button>
          </div>
          <div className="text-center my-3 font-bold text-xl">OR</div>
          <h2 className="font-bold text-2xl mb-3">Login</h2>
          <label className="font-bold">Username</label>
          <div className="flex my-3">
            <input
              type="text"
              placeholder="Username"
              value={state.loginUsername}
              onChange={(e) =>
                dispatch({
                  type: "SetLoginUsername",
                  payload: e.target.value,
                })
              }
              className="w-full bg-gray-200 rounded-full border-none"
            />
          </div>
          <div>
            <button
              type="button"
              className="mt-3 bg-yellow-500 py-1 px-3 rounded text-white font-bold w-full"
              onClick={() => handleLogin()}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

import "./App.css";
import { UserProvider } from "./providers/UserContext";
import { StateProvider } from "./providers/StateContext";
import Routes from "./Routes";
function App() {
  return (
    <>
      <UserProvider>
        <StateProvider>
          <Routes />
        </StateProvider>
      </UserProvider>
      <div>
        <div
          id="notification-fixed-container"
          className="fixed bottom-16 z-10"
        ></div>
      </div>
    </>
  );
}

export default App;
